<label *ngIf="label" class="form-control-label">{{ label }}</label>
<div
  [matMenuTriggerFor]="labeledMenu"
  #labeledMenuTrigger="matMenuTrigger"
  [class.control-focus]="isOpen"
  [class.highlighted]="highlighted"
  [class.highlighted-error]="highlightedError"
  class="form-control {{ buttonHeight }}"
  (menuOpened)="this.setIsOpen(true)"
  (menuClosed)="this.setIsOpen(false)"
  data-test="mode-labeled-menu-trigger"
  [ngClass]="{ disabled: menuDisabled }"
>
  <div class="display" data-test="menu-label-field" [ngClass]="{ placeholder: !selectedDisplayName }">
    {{ selectedDisplayName || placeholder }}
  </div>
  <ng-container #customComponent></ng-container>
  <capra-icon icon="icon-caret-down"></capra-icon>
</div>
<mat-menu #labeledMenu="matMenu" class="select-menu labeled-menu {{ menuClass }}">
  <ng-container *ngFor="let item of menuItems; trackBy: trackByName">
    <mode-labeled-menu-item
      [activeIcon]="activeIcon"
      [menuItem]="item"
      [isSelected]="this.isSelected(item)"
      (menuChange)="onChange($event)"
      capraTooltip
      [tooltipAppendTo]="document.body"
      [tooltipIsDisabled]="!item.tooltip"
      tooltipPlacement="bottom"
      [tooltipContent]="item.tooltip || ''"
      [tooltipInteractive]="false"
    ></mode-labeled-menu-item>
  </ng-container>
</mat-menu>
