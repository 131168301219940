/**
 * Store for HAL resources, excluding account resources. Accounts
 * are excluded because those will generally be keyed by username,
 * which is arbitrary.
 */

import { ModeHalResource } from '@mode/shared/contract-common';
import { isPresent } from '@mode/shared/util-js';

import { SwitchViewResponseTypes } from '@mode/shared/contract-common';
import {
  AccountResource,
  ApiKeyResource,
  ApiKeysResource,
  ColorPaletteResource,
  ScheduleResource,
  UserPreferenceResource,
} from './accounts/account-response.types';
import { ChartResource } from './charts/chart-response.types';
import { DatasourceResource } from './datasources/datasource-response.types';
import { ExplorationResource } from './explorations/exploration-response.types';
import { MetricDimensionResource } from './metric-dimensions/metric-dimension-response.types';
import { MetricResource } from './metrics/metric-response.types';
import { QueryRunResource } from './query-runs/query-run-response.types';
import { ReportRunResource } from './report-runs/report-run-response.types';
import { ReportResource } from './reports/report-response.types';
import { SpaceResource } from './spaces/spaces-response.types';
/**
 * Partially normalized collection cache, intended to store an array of items belonging
 * to a parent resource. Useful if you want to support CRUD operations
 * on the resources you pull from endpoints that return an array of items.
 *
 * Doesn't properly support optimistic add/delete. Recommended that you do a full
 * reload from the API in those cases. Cached reloads of domain state after an edit
 * is possible, but exercise caution in case an edit should result in a collection
 * excluding an item, for example.
 */
export class HalChildrenCache<T extends ModeHalResource> {
  cache = new Map<string, Array<string>>();

  constructor(
    private normalizedCache: HalCache<T>,
    private id: keyof T = 'token'
  ) {}

  get(key: string) {
    const keys = this.cache.get(key);
    if (keys) {
      /**
       * Excludes deleted items for safety.
       * A possible improvement would be if this class could be notified of a add/delete,
       * but supporting optimistic add/delete should be done with a lot of consideration
       * since it opens up a lot of edge cases.
       */
      return keys.map((key) => this.normalizedCache.get(key)).filter(isPresent);
    } else {
      return null;
    }
  }

  has(key: string) {
    return this.cache.has(key);
  }

  set(key: string, childResources: ReadonlyArray<T>) {
    this.cache.set(
      key,
      childResources.map((r) => r[this.id])
    );
    childResources.forEach((r) => {
      this.normalizedCache.set(r[this.id], r);
    });
  }

  delete(key: string): boolean {
    // TODO: Garbage collection of normalized cache?
    return this.cache.delete(key);
  }

  /**
   * Intended for unit testing cleanup
   */
  __clearCache() {
    this.cache.clear();
    this.normalizedCache.__clearCache();
  }
}

/**
 * This cache can support an array of resources in case you want to do a denormalized cache.
 */
export class HalCache<T extends ModeHalResource | ReadonlyArray<ModeHalResource>> {
  cache = new Map<string, T>();

  constructor() {}

  /**
   * Retrieves the resource for the provided key.
   * @param key
   */
  get(key: string): T | undefined {
    return this.cache.get(key);
  }

  has(key: string) {
    return this.cache.has(key);
  }

  set(key: string, resource: T) {
    this.cache.set(key, resource);
  }

  delete(key: string): boolean {
    return this.cache.delete(key);
  }

  /**
   * Intended for unit testing cleanup
   */
  __clearCache() {
    this.cache.clear();
  }
}

const reportCache = new HalCache<ReportResource>();
const accountCache = new HalCache<AccountResource>();
const userOrgsCache = new HalCache<AccountResource[]>();
const adminsCache = new HalCache<AccountResource[]>();
const preferenceCache = new HalCache<UserPreferenceResource>();
const allColorPaletteCache = new HalCache<ColorPaletteResource[]>(); // Caching all color palettes in an account
const colorPaletteCache = new HalCache<ColorPaletteResource>(); // Cache single palettes, probably from charts
const scheduleCache = new HalCache<ScheduleResource>();
const apiKeyCache = new HalCache<ApiKeyResource>();
const apiKeysCache = new HalCache<ApiKeysResource>();
const chartCache = new HalCache<ChartResource>();
const datasourceCache = new HalCache<DatasourceResource>();
const workspaceDatasourceCache = new HalChildrenCache<DatasourceResource>(datasourceCache);
const spaceCache = new HalCache<SpaceResource>();
const orgCollectionsCache = new HalChildrenCache<SpaceResource>(spaceCache);
const switchViewCache = new HalCache<SwitchViewResponseTypes.SwitchViewResource>();
const switchViewAttrCache = new HalCache<SwitchViewResponseTypes.SwitchViewAttrResource>();
const metricsCache = new HalCache<MetricResource>();
const dimensionsCache = new HalCache<MetricDimensionResource[]>();
const explorationCache = new HalCache<ExplorationResource>();
const reportRunCache = new HalCache<ReportRunResource>();
const queryRunCache = new HalCache<QueryRunResource>();

export {
  accountCache,
  adminsCache,
  userOrgsCache,
  allColorPaletteCache,
  colorPaletteCache,
  preferenceCache,
  chartCache,
  reportCache,
  spaceCache,
  switchViewCache,
  switchViewAttrCache,
  datasourceCache,
  workspaceDatasourceCache,
  orgCollectionsCache,
  metricsCache,
  dimensionsCache,
  explorationCache,
  reportRunCache,
  queryRunCache,
  scheduleCache,
  apiKeyCache,
  apiKeysCache,
};
