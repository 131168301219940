/**
 * This file is an abstraction for icon bundles that need to be imported
 * for use with `<capra-icon>` in the product.
 */

import {
  capraIconCaretDown,
  capraIconCalendar,
  capraIconGear,
  capraIconCaretRight,
  capraIconCheck,
  capraIconChevronLeft,
  capraIconChevronRight,
  capraIconClock,
  capraIconClose,
  capraIconDotIndicator,
  capraIconFieldBoolean,
  capraIconFieldCalcBoolean,
  capraIconFieldCalcDate,
  capraIconFieldCalcNumeric,
  capraIconFieldCalcString,
  capraIconFieldDate,
  capraIconFieldNumeric,
  capraIconFieldString,
  capraIconInfoOutline,
  capraIconPaginationFirst,
  capraIconPaginationLast,
  capraIconPaginationNext,
  capraIconPaginationPrevious,
  capraIconPencil,
  capraIconRelevantValues,
  capraIconSearch,
  capraIconWarning,
  capraIlloErrorDataset,
  capraIlloFunnelGray,
  capraIlloNoResultTable,
  completeIconSet,
  capraIconGranularityBack,
  capraAnalystStudioIlloAddMembersError,
  capraAnalystStudioIlloAddMembersSearch,
  capraAnalystStudioIlloAddMembersSuccess,
  capraAnalystStudioIlloAiGenerate,
  capraAnalystStudioIlloAirBalloon,
  capraAnalystStudioIlloChartThumbArea,
  capraAnalystStudioIlloChartThumbArea100,
  capraAnalystStudioIlloChartThumbBarHoriz,
  capraAnalystStudioIlloChartThumbBarVert,
  capraAnalystStudioIlloChartThumbBar100Horiz,
  capraAnalystStudioIlloChartThumbBar100Vert,
  capraAnalystStudioIlloChartThumbBigvalue,
  capraAnalystStudioIlloChartThumbDonut,
  capraAnalystStudioIlloChartThumbGroupedBarHoriz,
  capraAnalystStudioIlloChartThumbGroupedBarVert,
  capraAnalystStudioIlloChartThumbHistogram,
  capraAnalystStudioIlloChartThumbLineBar,
  capraAnalystStudioIlloChartThumbLine,
  capraAnalystStudioIlloChartThumbPie,
  capraAnalystStudioIlloChartThumbPivotTable,
  capraAnalystStudioIlloChartThumbScatterplot,
  capraAnalystStudioIlloChartThumbTable,
  capraAnalystStudioIlloConnectDatabase,
  capraAnalystStudioIlloDashboards,
  capraAnalystStudioIlloDatasetSql,
  capraAnalystStudioIlloDatasetUrl,
  capraAnalystStudioIlloDocs,
  capraAnalystStudioIlloEmptyReport,
  capraAnalystStudioIlloErrorDataset,
  capraAnalystStudioIlloFormError,
  capraAnalystStudioIlloFormSuccess,
  capraAnalystStudioIlloFunnelGray,
  capraAnalystStudioIlloGeneratingExportFail,
  capraAnalystStudioIlloGeneratingExportSuccess,
  capraAnalystStudioIlloGeneratingExport,
  capraAnalystStudioIlloGenericEmptyState,
  capraAnalystStudioIlloGenericSuccess,
  capraAnalystStudioIlloGenericWarning,
  capraAnalystStudioIlloGhostUiWarning,
  capraAnalystStudioIlloIconLock,
  capraAnalystStudioIlloInvite,
  capraAnalystStudioIlloLoading1,
  capraAnalystStudioIlloLoading,
  capraAnalystStudioIlloNewQueryEmptyState,
  capraAnalystStudioIlloNoResultTable,
  capraAnalystStudioIlloPdfReport,
  capraAnalystStudioIlloQueryEditor,
  capraAnalystStudioIlloSearchEmptyState,
  capraAnalystStudioIlloStudioRunPopover,
  capraAnalystStudioIlloTileNoPreview,
  capraAnalystStudioIlloTsLogoEmblem,
  capraAnalystStudioIlloUseData,
  capraAnalystStudioIlloVisualExplorer,
  capraAnalystStudioIlloVizEditorEmptyState,
  capraAnalystStudioIlloWriteSql,
} from '@mode/capra';

const analystStudioAppIcons = [
  capraAnalystStudioIlloAddMembersError,
  capraAnalystStudioIlloAddMembersSearch,
  capraAnalystStudioIlloAddMembersSuccess,
  capraAnalystStudioIlloAiGenerate,
  capraAnalystStudioIlloAirBalloon,
  capraAnalystStudioIlloChartThumbArea,
  capraAnalystStudioIlloChartThumbArea100,
  capraAnalystStudioIlloChartThumbBarHoriz,
  capraAnalystStudioIlloChartThumbBarVert,
  capraAnalystStudioIlloChartThumbBar100Horiz,
  capraAnalystStudioIlloChartThumbBar100Vert,
  capraAnalystStudioIlloChartThumbBigvalue,
  capraAnalystStudioIlloChartThumbDonut,
  capraAnalystStudioIlloChartThumbGroupedBarHoriz,
  capraAnalystStudioIlloChartThumbGroupedBarVert,
  capraAnalystStudioIlloChartThumbHistogram,
  capraAnalystStudioIlloChartThumbLineBar,
  capraAnalystStudioIlloChartThumbLine,
  capraAnalystStudioIlloChartThumbPie,
  capraAnalystStudioIlloChartThumbPivotTable,
  capraAnalystStudioIlloChartThumbScatterplot,
  capraAnalystStudioIlloChartThumbTable,
  capraAnalystStudioIlloConnectDatabase,
  capraAnalystStudioIlloDashboards,
  capraAnalystStudioIlloDatasetSql,
  capraAnalystStudioIlloDatasetUrl,
  capraAnalystStudioIlloDocs,
  capraAnalystStudioIlloEmptyReport,
  capraAnalystStudioIlloErrorDataset,
  capraAnalystStudioIlloFormError,
  capraAnalystStudioIlloFormSuccess,
  capraAnalystStudioIlloFunnelGray,
  capraAnalystStudioIlloGeneratingExportFail,
  capraAnalystStudioIlloGeneratingExportSuccess,
  capraAnalystStudioIlloGeneratingExport,
  capraAnalystStudioIlloGenericEmptyState,
  capraAnalystStudioIlloGenericSuccess,
  capraAnalystStudioIlloGenericWarning,
  capraAnalystStudioIlloGhostUiWarning,
  capraAnalystStudioIlloIconLock,
  capraAnalystStudioIlloInvite,
  capraAnalystStudioIlloLoading1,
  capraAnalystStudioIlloLoading,
  capraAnalystStudioIlloNewQueryEmptyState,
  capraAnalystStudioIlloNoResultTable,
  capraAnalystStudioIlloPdfReport,
  capraAnalystStudioIlloQueryEditor,
  capraAnalystStudioIlloSearchEmptyState,
  capraAnalystStudioIlloStudioRunPopover,
  capraAnalystStudioIlloTileNoPreview,
  capraAnalystStudioIlloTsLogoEmblem,
  capraAnalystStudioIlloUseData,
  capraAnalystStudioIlloVisualExplorer,
  capraAnalystStudioIlloVizEditorEmptyState,
  capraAnalystStudioIlloWriteSql,
];
/**
 * Application Icons
 *
 * This bundle represents all icons that are included in the application context
 * (meaning this does not include the embeds).
 */
export const APP_ICONS_MODE = completeIconSet.filter(
  // filter out Analyst Studio icons
  (icon) =>
    !analystStudioAppIcons.some(
      (analystStudioIcon) => analystStudioIcon.name === icon.name && analystStudioIcon.data === icon.data
    )
);

export const APP_ICONS_ANALYST_STUDIO = [
  // filter out icons that have an Analyst Studio equivalent
  ...APP_ICONS_MODE.filter(
    (icon) => !analystStudioAppIcons.some((analystStudioIcon) => analystStudioIcon.name === icon.name)
  ),
  // add Analyst Studio icons
  ...analystStudioAppIcons,
];

/**
 * Embed Icons
 *
 * Add any icons that need to be accessible in the embed here. Embed icons need to be
 * registered in both the `embed-report.module` and the `embed-report-builder.module` as
 * they act as two separate entrypoints. This bundle will help facilitate maintaining
 * the icons available between those two entry points, as we can assume they're always
 * the same.
 *
 * Note - We should only add icons to this bundle when they are necessary in the embed.
 *   The intention is to keep this bundle smaller than the general app bundle by only
 *   including icons that are specifically required in the embed.
 */
export const EMBED_ICONS_MODE = [
  capraIconCaretDown,
  capraIconCalendar,
  capraIconGear,
  capraIconCaretRight,
  capraIconCheck,
  capraIconChevronLeft,
  capraIconChevronRight,
  capraIconClock,
  capraIconClose,
  capraIconDotIndicator,
  capraIconFieldBoolean,
  capraIconFieldCalcBoolean,
  capraIconFieldCalcDate,
  capraIconFieldCalcNumeric,
  capraIconFieldCalcString,
  capraIconFieldDate,
  capraIconFieldNumeric,
  capraIconFieldString,
  capraIconGranularityBack,
  capraIconInfoOutline,
  capraIconPaginationFirst,
  capraIconPaginationLast,
  capraIconPaginationNext,
  capraIconPaginationPrevious,
  capraIconPencil,
  capraIconRelevantValues,
  capraIconSearch,
  capraIconWarning,
  capraIlloErrorDataset,
  capraIlloFunnelGray,
  capraIlloNoResultTable,
];

export const EMBED_ICONS_ANALYST_STUDIO = [...EMBED_ICONS_MODE];
