import { CommonModule } from '@angular/common';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { IconComponent } from '../icon/icon.component';
import { ThoughtspotIntegrationService } from '@mode/shared/util-thoughtspot-integrated';

export const BADGE_TYPES = [
  'green',
  'blue',
  'purple',
  'dark-purple',
  'pink',
  'orange',
  'yellow',
  'dark-yellow',
  'tan',
  'gray',
  'dark-gray',
] as const;

export type BadgeType = (typeof BADGE_TYPES)[number];

/**
 * The badge component handles the implementation of all of our contextual badge
 * types. Parameters are avaialable to configure presentation.
 */
@Component({
  standalone: true,
  selector: 'capra-badge',
  imports: [CommonModule, IconComponent],
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  /** The badge text */
  @Input() text = '';

  /** The badge type determines color and style */
  @Input() type: BadgeType = 'blue';

  /** An optional icon to insert to the left of the text.
   * See Icon Component for more information.
   */
  @Input() icon: string | null = null;

  readonly isAnalystStudio = ThoughtspotIntegrationService.currOrgIntegrated();
}
