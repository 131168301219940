import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { ApiService } from '@mode/shared/util-halogen';
import { orgCollectionsCache } from '../resource-cache';
import { SharedDataAccessWebappModule } from '../shared-data-access-webapp.module';
import { AccountFetchService } from './account-fetch.service';
import { ApiTypes } from '@mode/shared/contract-common';
import { SpaceResource } from '../spaces/spaces-response.types';

@Injectable({
  providedIn: SharedDataAccessWebappModule,
})
export class OrgCollectionsFetchService {
  constructor(
    private accountFetcher: AccountFetchService,
    private apiService: ApiService
  ) {}

  getCollections(username: string, options?: ApiTypes.ResourceOptions, noCache?: boolean) {
    const cached = orgCollectionsCache.get(username);
    if (cached == null || noCache) {
      return this.accountFetcher.getAccountResource(username).pipe(
        switchMap((account) => from(this.apiService.getResources<SpaceResource>(account, 'spaces', options))),
        tap((spaces) => orgCollectionsCache.set(username, spaces))
      );
    } else {
      return of(cached);
    }
  }
}
