import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { fromEvent, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrowserEventsService {
  public readonly beforeUnloadEvent$: Observable<Event> = fromEvent(this._window, 'beforeunload');
  public readonly visibilityChangeEvent$ = fromEvent(this._document, 'visibilitychange');
  public readonly storageEvent$: Observable<StorageEvent> = fromEvent<StorageEvent>(this._window, 'storage');
  constructor(
    private _window: Window,
    @Inject(DOCUMENT) private _document: Document
  ) {}
}
