import { Inject, Injectable } from '@angular/core';
import { ApiTypes } from '@mode/shared/contract-common';
import { ApiService, TemplateParser, TEMPLATE_PARSER } from '@mode/shared/util-halogen';
import { from, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { accountCache, adminsCache, userOrgsCache } from '../resource-cache';
import { SharedDataAccessWebappModule } from '../shared-data-access-webapp.module';
import { AccountResource } from './account-response.types';

@Injectable({ providedIn: SharedDataAccessWebappModule })
export class AccountFetchService {
  private readonly _accountEntry = this.urlTemplateService.parse('/api/{username}');

  constructor(
    private apiService: ApiService,
    @Inject(TEMPLATE_PARSER) private urlTemplateService: TemplateParser
  ) {}

  getAccountResource(username: string, options?: ApiTypes.ResourceOptions) {
    const cached = accountCache.get(username);
    if (cached == null) {
      return from(this.apiService.getFromPath<AccountResource>(this._accountEntry.expand({ username }), options)).pipe(
        tap((resource) => {
          accountCache.set(username, resource);
        })
      );
    } else {
      return of(cached as AccountResource); // hopefully we can assume this is a user
    }
  }

  getUserOrgResources(username: string, options?: ApiTypes.ResourceOptions) {
    const cached = userOrgsCache.get(username);
    if (cached == null) {
      return this.getAccountResource(username, options).pipe(
        switchMap((account) => this.apiService.getResources<AccountResource>(account, 'organizations')),
        tap((orgs) => userOrgsCache.set(username, orgs))
      );
    } else {
      return of(cached);
    }
  }

  getAdminResources(username: string, options?: ApiTypes.ResourceOptions) {
    const cached = adminsCache.get(username);
    if (cached == null) {
      return this.getAccountResource(username, options).pipe(
        switchMap((account) => this.apiService.getResources<AccountResource>(account, 'admins')),
        tap((orgs) => adminsCache.set(username, orgs))
      );
    } else {
      return of(cached);
    }
  }
}
