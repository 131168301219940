import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IconComponent } from '@mode/capra';
import { ThoughtspotIntegrationService } from '@mode/shared/util-thoughtspot-integrated';
import loaderPrimaryGif from './loader-primary.gif';
import loaderSecondaryGrayGif from './loader-secondary-gray.gif';
import loaderSecondaryGreenGif from './loader-secondary-green.gif';

/**
 * Determines which loader asset will be shown.

 * @type `primary`: for pages/components (except dialogs) that have no other loading state
 * @type `secondaryGray`: for containers where the loading time could exceed 5 seconds for large orgs
 * @type `secondaryGreen`: for containers where the loading time could exceed 5 seconds for large orgs
 * @type `tertiary`: for containers where the loading time is less than 5 seconds, regardless of org size
 */
export type LoaderType = 'primary' | 'secondaryGray' | 'secondaryGreen' | 'tertiary';

@Component({
  standalone: true,
  selector: 'mode-loader',
  imports: [CommonModule, IconComponent, NgOptimizedImage],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent implements OnInit {
  @Input() type: LoaderType = 'primary';

  private readonly _loaderDetails: Record<
    LoaderType,
    {
      element: 'image' | 'icon';
      value: string;
      size: number;
    }
  > = {
    primary: {
      element: 'image',
      value: loaderPrimaryGif,
      size: 80,
    },
    secondaryGray: {
      element: 'image',
      value: loaderSecondaryGrayGif,
      size: 60,
    },
    secondaryGreen: {
      element: 'image',
      value: loaderSecondaryGreenGif,
      size: 60,
    },
    tertiary: {
      element: 'icon',
      value: 'illo-loading',
      size: 312,
    },
  };

  isAnalystStudio$ = this.thoughtspotIntegrationService.currOrgIntegrated$;
  loader = this._loaderDetails[this.type];

  constructor(private thoughtspotIntegrationService: ThoughtspotIntegrationService) {}

  ngOnInit(): void {
    this.loader = this._loaderDetails[this.type];
  }
}
