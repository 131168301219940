/**
 * Allowing data-access importing here because app-initializer needs to get
 * account information, but don't want to load the entire Account Domain module
 */
// eslint-disable-next-line @nx/enforce-module-boundaries
import { InitialAccountsClientService, SharedDataAccessWebappModule } from '@mode/shared/data-access-webapp';

// Importing here so interceptors can be initialized in time
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SharedDataAccessFlamingoModule } from '@mode/shared/data-access-flamingo';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SchemasDataAccessModule } from '@mode/queries/schemas/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { QueriesAiAssistDomainModule } from '@mode/queries/ai-assist/domain';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SharedEnvironmentDomainModule } from '@mode/shared/environment/domain';

import { Location } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationRef, Compiler, Injector, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule } from '@angular/material/legacy-tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationStart, Router, RouterModule, UrlHandlingStrategy, UrlSerializer, UrlTree } from '@angular/router';
import { CapraIconsRegistry } from '@mode/capra';
import { ObservabilityService, WebVitalsObservabilityService } from '@mode/shared/util-observability';
import { SharedUtilLoggingModule } from '@mode/shared/util-logging';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';

import { ReportViewerUiModule } from '@mode/report-viewer/ui';
import { SharedUtilFeatureFlagsModule } from '@mode/shared/util-feature-flags';

import { AppComponent } from './app.component';

import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { CookieModule } from 'ngx-cookie';
import { DndModule } from 'ngx-drag-drop';
import { ToastrModule } from 'ngx-toastr';

import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { SharedUiModule } from '@mode/shared/ui';
import { BrowserService, HelpDocsService, MetaTags, MetaTagsService } from '@mode/shared/util-js';
import { MessageToastComponent, SharedUtilToastModule } from '@mode/shared/util-toast';
import { appInitializer } from './app-initializer';
import { AppRoutes, SxSRouteHandlingStrategy } from './app.routes';
import { STORE_DEV_TOOLS } from './ngrx-dev-tools';
// TODO: refactor feature-sessions so we can use session facade for all communication instead
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SubmitFeedbackDialogService } from '@mode/queries/ai-assist/feature-query-viewer';
import { NegativeFeedbackToastComponent, PositiveFeedbackToastComponent } from '@mode/queries/ai-assist/ui';
import { FeedbackDialogService, NegativeFeedbackToast, PositiveFeedbackToast } from '@mode/queries/ai-assist/util';
import { SessionService, SessionsFeatureSessionModule } from '@mode/sessions/feature-session';
import { AI_GATEWAY_AUTH_FACADE, DATA_INDEX_AUTH_FACADE } from '@mode/shared/authorization/contract';
import {
  ErrorReporter,
  FeatureFlag,
  FeatureFlagsFacade,
  FeatureFlagsInitializationService,
  LegacyAssetService,
} from '@mode/shared/contract-common';
import { EnvironmentFacade } from '@mode/shared/environment/contract';
import { errorGif } from '@mode/shared/ui-images';
import { BugsnagEffects, BugsnagErrorService } from '@mode/shared/util-error-handling';
import { initTracers, recordEvent } from '@mode/shared/util-tracing';
import { Observable, combineLatest } from 'rxjs';
import { first, skipWhile } from 'rxjs/operators';
import { AiGatewayAuthFacade, DataIndexAuthFacade } from '@mode/shared/authorization/domain';
import { webVitalsInitializer } from './webvitals-initializer';
import {
  SharedUtilThoughtspotIntegratedModule,
  ThoughtspotIntegrationService,
} from '@mode/shared/util-thoughtspot-integrated';

import Bugsnag from '@bugsnag/js';

if (window.BugsnagConfig?.loadingDeferred) Bugsnag.start(window.BugsnagConfig);

@NgModule({
  declarations: [AppComponent],
  imports: [
    ContextMenuModule.forRoot(),
    CookieModule.forRoot(),
    DndModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    SchemasDataAccessModule,
    QueriesAiAssistDomainModule,
    SharedUtilFeatureFlagsModule,
    SharedUtilThoughtspotIntegratedModule,
    SharedUtilLoggingModule,
    SharedEnvironmentDomainModule,
    SharedDataAccessFlamingoModule, // To get the interceptors loaded
    SharedDataAccessWebappModule,
    SessionsFeatureSessionModule,
    StoreModule.forRoot(
      {},
      {
        // need to turn off these checks because we mutate state :(
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    EffectsModule.forRoot([BugsnagEffects]),
    environment.production // Exclude ngrx devtools from prod build. Done this way because logOnly below did not work as expected
      ? []
      : STORE_DEV_TOOLS,
    ReportViewerUiModule,
    MatExpansionModule,
    MatLegacyMenuModule,
    MatLegacyTabsModule,
    MatLegacyTableModule,
    MatLegacyAutocompleteModule,
    RouterModule.forRoot(AppRoutes, {
      initialNavigation: 'disabled',
      enableTracing: false,
    }),
    SharedUiModule,
    SharedUtilToastModule,
    ToastrModule.forRoot({
      positionClass: 'mode-toast-position',
      toastClass: 'toast',
      toastComponent: MessageToastComponent,
      timeOut: 4000,
      tapToDismiss: true,
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [
        FeatureFlagsInitializationService,
        ThoughtspotIntegrationService,
        MetaTagsService,
        ObservabilityService,
        CapraIconsRegistry,
        EnvironmentFacade,
        InitialAccountsClientService,
        HelpDocsService,
      ],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: webVitalsInitializer,
      deps: [FeatureFlagsFacade, WebVitalsObservabilityService],
      multi: true,
    },
    { provide: Window, useValue: window },
    { provide: UrlHandlingStrategy, useClass: SxSRouteHandlingStrategy },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
    { provide: ErrorReporter, useClass: BugsnagErrorService },
    { provide: DATA_INDEX_AUTH_FACADE, useExisting: DataIndexAuthFacade },
    { provide: AI_GATEWAY_AUTH_FACADE, useExisting: AiGatewayAuthFacade },
    { provide: LegacyAssetService, useValue: { ...window.ASSET_PATH_MAP, errorGif } },
    { provide: NegativeFeedbackToast, useValue: NegativeFeedbackToastComponent },
    { provide: PositiveFeedbackToast, useValue: PositiveFeedbackToastComponent },
    { provide: FeedbackDialogService, useClass: SubmitFeedbackDialogService },
  ],
})
export class AppModule {
  legacyLoaded = false;
  loggedOutSignInFlowEnabled$: Observable<boolean>;
  loggedOutTrackingEnabled$: Observable<boolean>;

  constructor(
    private router: Router,
    private injector: Injector,
    private compiler: Compiler,
    private urlSerializer: UrlSerializer,
    private location: Location,
    private sessionService: SessionService,
    private featureFlagsFacade: FeatureFlagsFacade,
    private browserService: BrowserService,
    private metaTagService: MetaTagsService,
    private environmentFacade: EnvironmentFacade,
    private errorService: ErrorReporter,
    private window: Window
  ) {
    this.loggedOutSignInFlowEnabled$ = this.featureFlagsFacade.asObservable(FeatureFlag.LoggedOutSignInFlow);
    this.loggedOutTrackingEnabled$ = this.featureFlagsFacade.asObservable(FeatureFlag.LoggedOutTracking);
  }

  checkAndLoadLegacyModule(url: UrlTree) {
    // Workspace settings still needs `mode.main` module to be loaded
    const isNotWorkspaceSettings = !/^\/organizations.*/.test(url.toString());
    const forAngularOnly = this.router['urlHandlingStrategy'].shouldProcessUrl(url) && isNotWorkspaceSettings;

    if (!forAngularOnly && !this.legacyLoaded) {
      this.legacyLoaded = true;
      /* This code loads all the angularjs libraries, so we'll ignore the boundary rules here
       */
      /* eslint-disable @nx/enforce-module-boundaries */
      switch (document.body.getAttribute('mode-app')) {
        case 'mode.signup': {
          /* Routes:
           *  - organizations/new
           */
          this.loadLegacyModule(
            import('../../../../libs/shared/legacy/src/modules/signup/signup-shell.module'),
            ({ SignupShellModule }: any) => SignupShellModule
          );
          break;
        }
        case 'mode.importer': {
          /* Routes:
           * - /:user_name/uploads/new
           */
          this.loadLegacyModule(
            import('../../../../libs/shared/legacy/src/modules/importer/importer-shell.module'),
            ({ ImporterShellModule }: any) => ImporterShellModule
          );
          break;
        }
        case 'mode.search': {
          /* Routes:
           * - /search
           */
          this.loadLegacyModule(
            import('../../../../libs/shared/legacy/src/modules/search/search-shell.module'),
            ({ SearchShellModule }: any) => SearchShellModule
          );
          break;
        }
        case 'mode.editor': {
          /* Routes:
           * - /editor
           */
          this.loadLegacyModule(
            import('../../../../libs/shared/legacy/src/modules/editor-shell/editor-shell.module'),
            ({ EditorShellModule }: any) => EditorShellModule
          );
          break;
        }
        case 'mode.report': {
          /* Routes:
           * - /reports (and not /editor)
           */
          this.loadLegacyModule(
            import('../../../../libs/shared/legacy/src/modules/report-viewer/report-viewer-shell.module'),
            ({ ReportViewerShellModule }: any) => ReportViewerShellModule
          );
          break;
        }
        case 'mode.home': {
          /* Routes:
           * - /home
           * - :org_name/spaces
           */
          this.loadLegacyModule(
            import('../../../../libs/shared/legacy/src/modules/home/home-shell.module'),
            ({ HomeShellModule }: any) => HomeShellModule
          );
          break;
        }
        case 'mode.sign-in': {
          /* Routes:
           * - /signin
           */
          this.loadLegacyModule(
            import('../../../../libs/shared/legacy/src/modules/sign-in/app-sign-in/sign-in-shell.module'),
            ({ SignInShellModule }: any) => SignInShellModule
          );
          break;
        }
        case 'mode.main': {
          /* Routes:
           * - /organizations/:org_name
           * - /settings
           * - /signin
           * - This is the default, so anything else
           */
          this.loadLegacyModule(
            import('../../../../libs/shared/legacy/src/modules/main/main-shell.module'),
            ({ MainShellModule }: any) => MainShellModule
          );
          break;
        }
        default: {
          throw new Error('Failed to find matching legacy app!');
        }
      }
      /* eslint-enable @nx/enforce-module-boundaries */
    }
  }

  loadLegacyModule(importPromise: Promise<unknown>, getter: (module: unknown) => any) {
    importPromise
      .then((m) => this.compiler.compileModuleAsync(getter(m)))
      .then((factory) => factory.create(this.injector))
      .catch((error) => {
        if (/ChunkLoadError/.test(error.name)) {
          return this.errorService.notify({ error, groupingHash: 'LegacyModuleChunkLoadError', severity: 'error' });
        }

        return this.errorService.notify({ error, groupingHash: 'LegacyModuleImportError', severity: 'error' });
      });
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    recordEvent('document-visibility', 'initial-state', { state: document.visibilityState });
    document.addEventListener('visibilitychange', () => {
      recordEvent('document-visibility', 'changed', { state: document.visibilityState });
    });

    combineLatest([
      this.featureFlagsFacade.asObservable(FeatureFlag.NewReportViewerTracing),
      this.environmentFacade.openTelemetryConfig$,
    ])
      .pipe(first())
      .subscribe(([newReportViewerTracingEnabled, openTelemetryConfig]) => {
        const disabled = new Set<string>();
        if (!newReportViewerTracingEnabled) {
          disabled.add('report-view-render');
        }

        initTracers(
          {
            startTime: window.performance.timeOrigin,
            app: 'main',
            username: this.metaTagService.getTag(MetaTags.CurrentUsername),
            account:
              this.metaTagService.getTag(MetaTags.OwnerUsername) ||
              this.metaTagService.getTag(MetaTags.ReportOwnerUsername),
          },
          openTelemetryConfig,
          new Set(disabled)
        );
      });

    appRef.bootstrap(AppComponent);

    /**
     * The following block is a way to lazy-load the shared-legacy module without going through
     * the router. Since we're leveraging UrlHandlingStrategy to enable/disable Angular/ui-router
     * routes, we can't rely solely on the Angular Router to load the legacy module because if
     * Angular Router doesn't process the route, it can't lazy-load the legacy module, so kind of a
     * catch-22.
     */
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        this.checkAndLoadLegacyModule(this.router.getCurrentNavigation().extractedUrl);
      }
    });

    // Initial navigation goes at the end so the first NavigationEnd event can be caught.
    this.router.initialNavigation();

    // If we're on a non-angular route, no router events will fire, so check for load legacy based on the window
    this.checkAndLoadLegacyModule(this.urlSerializer.parse(this.location.path(true)));

    combineLatest([this.loggedOutSignInFlowEnabled$.pipe(first()), this.loggedOutTrackingEnabled$.pipe(first())])
      .pipe(
        // don't run if neither flag is enabled
        skipWhile(([flowEnabled, analyticsEnabled]): boolean => !flowEnabled && !analyticsEnabled),
        // don't run on signin page
        skipWhile((): boolean => !!window.document.querySelector('[mode-app="mode.signup"]')),
        // don't run on signin page
        skipWhile((): boolean => !!window.document.querySelector('.mode-nav.signed-out-header')),
        // don't run on embed report viewer
        skipWhile((): boolean => !!document.querySelector('[mode-app="mode.report"].mode-runner')),
        // don't run on WLE
        skipWhile((): boolean => !!document.querySelector('[mode-app="embed.report"]')),
        // don't run on externally shared report
        skipWhile((): boolean => !!`${window.location.href}`.match(/secret_key=[a-z0-9]+$/)),
        // don't run on community reports
        skipWhile(
          (): boolean =>
            !!document.querySelector('[mode-app="mode.report"]') &&
            !this.metaTagService.getTag(MetaTags.CurrentUsername)
        )
      )
      .subscribe(() => {
        this.browserService.watchTabs();
        this.sessionService.watchSession();
      });
  }
}
