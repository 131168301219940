import { GlobalData, preInitEvents, eventNotifier, EventRecord, EventOptions, EventCreator } from './event-store';
import { HoneycombProvider } from './honeycomb-provider';
import { SegmentProvider } from './segment-provider';

const perf = typeof performance !== 'undefined' ? performance : { now: () => Date.now(), timeOrigin: 0 };
let globalData: GlobalData | undefined;
let initialized = false;

type NameFromEvent<ER> = ER extends EventRecord<infer N, string> ? N : never;
type ActionFromEvent<ER> = ER extends EventRecord<string, infer A> ? A : never;
type AttrsFromEvent<ER> = ER extends EventRecord<string, string, infer CA> ? CA : any;

interface OpenTelemetryConfig {
  collectorUrl: string;
}

export function recordEvent<EC extends ReturnType<EventCreator>>(event: EC): void;
export function recordEvent<
  EV extends EventRecord<TName, Action, CA>,
  TName extends string = NameFromEvent<EV>,
  Action extends string = ActionFromEvent<EV>,
  CA extends {} = AttrsFromEvent<EV>,
>(traceName: TName, action: Action, eventInfo?: EventOptions<CA>): void;
export function recordEvent<
  EC extends ReturnType<EventCreator>,
  EV extends EventRecord<TName, Action, CA>,
  TName extends string = NameFromEvent<EV>,
  Action extends string = ActionFromEvent<EV>,
  CA extends {} = AttrsFromEvent<EV>,
>(nameOrEvent: TName | EC, action?: Action, eventInfo?: EventOptions<CA>) {
  let traceName = '';
  let timestamp = perf.now() + perf.timeOrigin;

  if (typeof nameOrEvent === 'string') {
    if (eventInfo?.timestamp) {
      timestamp = eventInfo.timestamp;
    }

    traceName = nameOrEvent;
  } else {
    if (nameOrEvent.timestamp) {
      timestamp = nameOrEvent.timestamp;
    }

    action = nameOrEvent.action as Action;
    traceName = nameOrEvent.traceName;

    // Odd random block here so object spread can be used to pick out action and traceName
    {
      const { action, traceName, ...info } = nameOrEvent;
      eventInfo = info as unknown as EventOptions<CA>;
    }
  }

  const record = {
    ...eventInfo,
    ...globalData,
    url: typeof window !== 'undefined' ? window.location.href : undefined,
    traceName,
    action: action as string,
    timestamp,
  };

  eventNotifier.next(record);

  // Don't accumulate events after consumers have been initialized
  if (!initialized) {
    preInitEvents.push(record);
  }
}

/**
 * Initialize the tracing framework, including all consumers. Store any global
 * information for use by consumers and potentially (in the future) process any
 * hanging traces.
 */
export async function initTracers(
  data: GlobalData,
  openTelemetryConfig: OpenTelemetryConfig,
  disabledTracers: Set<string> = new Set()
) {
  globalData = data;

  switch (data.app) {
    case 'main':
      const honeycombProvider = new HoneycombProvider(openTelemetryConfig.collectorUrl, 'webapp-ui');
      const imports = [];
      if (typeof window !== 'undefined') {
        const segmentProvider = new SegmentProvider(window as any);
        imports.push(
          import('./consumers/helix-csv-export-segment').then((consumer) => consumer.init(data, segmentProvider))
        );
      }

      imports.push(
        import('./consumers/helix-csv-export-honeycomb').then((consumer) => consumer.init(data, honeycombProvider))
      );

      if (!disabledTracers.has('report-view-render')) {
        imports.push(
          import('./consumers/report-view-render-honeycomb').then((consumer) => consumer.init(data, honeycombProvider))
        );
      }

      await Promise.all(imports);
      break;
  }

  initialized = true;
}
