import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { ColorPaletteResource } from './account-response.types';
import { ApiService } from '@mode/shared/util-halogen';
import { allColorPaletteCache, colorPaletteCache } from '../resource-cache';
import { SharedDataAccessWebappModule } from '../shared-data-access-webapp.module';
import { AccountFetchService } from './account-fetch.service';
import { ApiTypes } from '@mode/shared/contract-common';

@Injectable({
  providedIn: SharedDataAccessWebappModule,
})
export class ColorPaletteFetchService {
  constructor(
    private accountFetcher: AccountFetchService,
    private apiService: ApiService
  ) {}

  getAllColorPaletteResources(username: string, options?: ApiTypes.ResourceOptions) {
    const cached = allColorPaletteCache.get(username);
    if (cached == null) {
      return this.accountFetcher.getAccountResource(username).pipe(
        switchMap((account) =>
          from(
            this.apiService.getResources<ColorPaletteResource>(account, 'all_color_palettes', {
              relationAs: 'color_palettes',
              ...options,
            })
          )
        ),
        tap((palettes) => {
          allColorPaletteCache.set(username, palettes);
          palettes.forEach((palette) => colorPaletteCache.set(palette.token, palette));
        })
      );
    } else {
      return of(cached);
    }
  }
}
