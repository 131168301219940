import { ChartTypes } from '@mode/shared/contract-common';
import { clamp } from 'lodash';
import { formatInUTC } from '../time-format';
import { getTextFormatByCurrencyPreset } from './table-format-helpers';
import { getDatePatternValueFromValue } from './table-format-settings';

type TextFormat = ChartTypes.TextFormat;

// Helpers to represent defaults if undefined
const DEFAULT_DECIMAL_STYLE: ChartTypes.DigitSeparator = '.';
const DEFAULT_SEPARATOR: ChartTypes.DigitSeparator = ',';
const DEFAULT_GROUPING: ChartTypes.DigitGrouping = 3;

/**
 * Flat table value formatter functions.
 *
 * These functions are available to format the cell values.
 */

/**
 * Helper to handle regional number formatting.
 */
export function formatNumberRegionally(
  stringValue: string,
  decimalSymbol: string,
  thousandsSeparator: string,
  thousandsGrouping: number
): string {
  // only do anything here if all necessary params are set
  if (decimalSymbol && thousandsSeparator && thousandsGrouping) {
    // regex taken from here: https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    const pattern = new RegExp(`\\B(?=(\\d{${thousandsGrouping}})+(?!\\d))`, 'g');

    // Break up the number by the decimal if it exists, then add separators and rejoin
    const parts = stringValue.toString().split('.');
    parts[0] = parts[0].replace(pattern, thousandsSeparator);

    return parts.join(decimalSymbol);
  } else {
    return stringValue;
  }
}

/**
 * Default formatter.
 *
 * Handle potentially huge strings. Trim to a max of 1000 characters when over the limit.
 * This formatter will not be used in the expanded view of a cell, instead the entire
 * contents will be displayed. A more robust contextual solution will need to be implemented
 * if we have additional default formatter settings in the future.
 */
export function defaultFormatter(value: string, formatOptions: TextFormat): string {
  if (value.length > 1000) {
    return value.substring(0, 1000).trim() + '...';
  }

  return value;
}

/**
 * Formats basic numbers, including separator and decimal customization.
 *
 * @param {number} value value
 * @param {TextFormat} formatOptions format options
 * @param {boolean} isAutoFormattable true iff additional formatting can be applied to the default 'Auto' format
 */
export function numberFormatter(value: number, formatOptions: TextFormat, isAutoFormattable?: boolean): string {
  // Check for bigints returned from flamingo as a string.
  if (typeof value === 'string') {
    return value;
  }

  // Get decimal, thousands separator, and grouping.
  const decimalStyle = formatOptions.decimalStyle ?? DEFAULT_DECIMAL_STYLE;
  let separator = formatOptions.separator ?? DEFAULT_SEPARATOR;
  const grouping = formatOptions.grouping ?? DEFAULT_GROUPING;

  // By default, use the value's string representation.
  let stringValue = '' + value;

  // If display units or precision are specified, apply them...
  const absValue = Math.abs(value);
  if (formatOptions.displayUnit) {
    stringValue = displayUnitFormatter(value, formatOptions.displayUnit, formatOptions.precision);
  } else if (formatOptions.precision !== undefined) {
    stringValue = value.toFixed(formatOptions.precision);
  }

  // ...otherwise, assume the default 'Auto' format is being used, so limit the precision and apply additional formatting.
  else if (absValue > 0) {
    // Limit the precision, if it uses fewer digits than the default string representation.
    // This prevents long repeating decimals such as 0.333333333333.
    const SIG = 4;
    // toFixed can only handle digits between 0 and 100. Cap the nDigits here. This will get formatted
    // with exponential notation below if it is auto format anyway.
    let nDigits = absValue >= 1 ? SIG : Math.min(99, SIG - Math.log10(absValue)); // SIG significant digits after the decimal
    let fixedValue = value.toFixed(nDigits);
    if (stringValue.length > fixedValue.length) {
      stringValue = fixedValue;
    }

    // Check whether this is a floating-point error, differing from a rounded value only in the lower bits.
    // If so, and if it uses fewer digits, display the rounded value.
    const pow = Math.pow(10, nDigits);
    const rounded = Math.round(value * pow) / pow;
    if (Math.abs(rounded - value) < 1000 * Number.EPSILON * absValue) {
      const roundedValue = '' + rounded;
      if (stringValue.length > roundedValue.length) {
        stringValue = roundedValue;
      }
    }

    // Apply additional formatting if requested.
    if (isAutoFormattable) {
      // For extreme values, use exponential format, with trailing zeroes removed...
      if (absValue >= 1e15 || absValue <= 1e-7) {
        stringValue = value.toExponential(SIG - 1); // SIG digits
        const j = stringValue.indexOf('e');
        let i = j - 1;
        while (stringValue.charAt(i) === '0') {
          i--;
        }
        if (stringValue.charAt(i) === decimalStyle) {
          i--;
        }
        stringValue = stringValue.slice(0, i + 1) + stringValue.slice(j);
      }

      // ...otherwise, limit precision and add separators.
      else {
        nDigits = absValue >= 1000 ? 0 : SIG - Math.log10(absValue); // at least SIG significant digits
        fixedValue = value.toFixed(nDigits);
        if (stringValue.length > fixedValue.length) {
          stringValue = fixedValue;
        }
        if (separator === '') {
          separator = DEFAULT_SEPARATOR;
        }
      }
    }
  }

  // Return value with regional formatting.
  return formatNumberRegionally(stringValue, decimalStyle, separator, grouping);
}

/**
 * Format percents.
 */
export function percentFormatter(value: number, formatOptions: TextFormat): string {
  // Check for bigints returned from flamingo as a string
  if (typeof value === 'string') {
    return value;
  }

  if (formatOptions.percentTransform) {
    value *= 100;
  }
  const precision = formatOptions.precision ?? getDefaultPrecision(value);
  const stringValue = value.toFixed(precision);
  const decimalStyle = formatOptions.decimalStyle ?? DEFAULT_DECIMAL_STYLE;
  const separator = formatOptions.separator ?? DEFAULT_SEPARATOR;
  const grouping = formatOptions.grouping ?? DEFAULT_GROUPING;

  const regionalValue = formatNumberRegionally(stringValue, decimalStyle, separator, grouping);

  return regionalValue + '%';
}

/**
 * Format dates.
 */
export function dateFormatter(value: number, formatOptions: TextFormat): string {
  // TODO: what does `DateTime` col type look like? I'm assuming it's not a timestamp, do I handle here or a new renderer
  const datePattern =
    formatOptions && formatOptions.dateFormat ? getDatePatternValueFromValue(formatOptions.dateFormat) : null;

  if (datePattern) {
    return formatInUTC(new Date(value), datePattern);
  } else {
    return new Date(value).toISOString();
  }
}

/**
 * Format financial numbers.
 */
export function financialFormatter(value: number, formatOptions: TextFormat): string {
  // Check for bigints returned from flamingo as a string
  if (typeof value === 'string') {
    return value;
  }
  if (formatOptions.currencyPreset !== 'custom') {
    // Get the relevant properties to persist and spread over the necessary settings.
    const currencyPreset = formatOptions.currencyPreset ?? ChartTypes.CurrencyPreset.USD;
    formatOptions = {
      type: formatOptions.type,
      shortcutType: formatOptions.shortcutType,
      colType: formatOptions.colType,
      horizontalAlignment: formatOptions.horizontalAlignment,
      precision: formatOptions.precision,
      negativeBehavior: formatOptions.negativeBehavior,
      displayUnit: formatOptions.displayUnit,
      ...getTextFormatByCurrencyPreset(currencyPreset),
    };
  }
  const precision = formatOptions.precision ?? getDefaultPrecision(value);
  let stringValue = Math.abs(value).toFixed(precision);

  if (formatOptions.displayUnit) {
    stringValue = displayUnitFormatter(Math.abs(value), formatOptions.displayUnit, precision);
  }

  const decimalStyle = formatOptions.decimalStyle ?? DEFAULT_DECIMAL_STYLE;
  const separator = formatOptions.separator ?? DEFAULT_SEPARATOR;
  const grouping = formatOptions.grouping ?? DEFAULT_GROUPING;

  let regionalValue = formatNumberRegionally(stringValue, decimalStyle, separator, grouping);

  if (formatOptions.currencySymbolSuffix) {
    regionalValue = regionalValue + ' ' + formatOptions.currencySymbolSuffix;
  }

  if (formatOptions.currencySymbolPrefix) {
    regionalValue = formatOptions.currencySymbolPrefix + regionalValue;
  }

  // Handle negative behavior, currently only have parens option
  if (value < 0 && formatOptions.negativeBehavior === 'parens') {
    regionalValue = `(${regionalValue})`;
  } else if (value < 0) {
    // minusSign
    regionalValue = `-${regionalValue}`;
  }

  return regionalValue;
}

/**
 * Get the appropriate formatter by format type
 */
export function getFormatterByType(
  formatType: ChartTypes.FormatType.Number | ChartTypes.FormatType.Percent | ChartTypes.FormatType.Financial
): (value: number, formatOptions: TextFormat) => string;
export function getFormatterByType(
  formatType: ChartTypes.FormatType.Date | ChartTypes.FormatType.Url | ChartTypes.FormatType.Default
): (value: string, formatOptions: TextFormat) => string;
export function getFormatterByType(
  formatType: ChartTypes.FormatType
): (value: any, formatOptions: TextFormat) => string;
export function getFormatterByType(
  formatType: ChartTypes.FormatType
): (value: any, formatOptions: TextFormat) => string {
  switch (formatType) {
    case ChartTypes.FormatType.Number:
      return numberFormatter;
    case ChartTypes.FormatType.Percent:
      return percentFormatter;
    case ChartTypes.FormatType.Date:
      return dateFormatter;
    case ChartTypes.FormatType.Financial:
      return financialFormatter;
    case ChartTypes.FormatType.Url:
      return defaultFormatter;
    case ChartTypes.FormatType.Default:
      return defaultFormatter;
    default:
      return defaultFormatter;
  }
}

export function displayUnitFormatter(
  target: number | string,
  suffix: ChartTypes.DisplayUnit,
  precision?: number
): string {
  let num = Number(target);
  const negativeCoefficient = num < 0 ? -1 : 1;
  num = Math.abs(num);
  const isBillions = suffix === 'B' || suffix === 'G';
  const isThousands = suffix === 'K';
  const isMillions = suffix === 'M';
  const getNum = (num: number, multiplier: number) =>
    ((num - (num % multiplier)) / multiplier + (num % multiplier) / multiplier) * negativeCoefficient;
  const formatter = (multiplier: number, suffix: ChartTypes.DisplayUnit) => {
    let displayValue = num;
    if (num % multiplier && num % multiplier !== num) {
      displayValue = getNum(num, multiplier);
    } else {
      displayValue = negativeCoefficient * (num / multiplier);
    }
    const actualPrecision = precision != null ? precision : getDefaultPrecision(displayValue);
    return displayValue.toFixed(actualPrecision) + suffix;
  };

  return isBillions
    ? formatter(1e9, suffix)
    : isMillions
      ? formatter(1e6, suffix)
      : isThousands
        ? formatter(1e3, suffix)
        : target.toString();
}

/**
 * Calculates a default minimum precision necessary to display something
 * for the specified value. If the absolute value is >=1, this will return 0 which
 * matches previous behavior. For decimal numbers, it returns enough precision to
 * show at least one non-zero digit.
 */
function getDefaultPrecision(value: number | undefined) {
  if (value == null) {
    return 0;
  }
  // Take the log of the absolute value to get the magnitude. Positive log
  // indicates a number >= 1. Negative log indicates a decimal value.
  // Log10(0) is -Infinity.
  // toFixed throws if the precision is not >= 0 and <100 so clamp the return
  // to that (reasonable) range.
  const logNum = Math.log10(Math.abs(value));
  return !Number.isFinite(logNum) || logNum >= 0 ? 0 : clamp(Math.ceil(Math.abs(logNum)), 0, 99);
}
