import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ErrorEvent, ErrorReporter } from '@mode/shared/contract-common';
import { BugsnagService, Event } from './bugsnag.service';

/**
 * Implements ErrorReporter to send reports to Bugsnag.
 *
 * This is not providedIn: 'root' because we don't want to depend on it directly. Only use ErrorReporter to access this.
 */
@Injectable()
export class BugsnagErrorService implements ErrorReporter {
  constructor(
    private bugsnagService: BugsnagService,
    @Inject(DOCUMENT) document: Document
  ) {
    // We've run into a weird issue where a small number of users are seeing CSP violations and it's causing the app styles no to be applied.
    // Bugsnag doesn't listen for these errors currently, so we'll manually report them.
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/securitypolicyviolation_event
    document.addEventListener('securitypolicyviolation', (event: SecurityPolicyViolationEvent) => {
      if (!event.blockedURI) {
        // Don't report unhelpful noise
        return;
      }

      const debugInfo = {
        blockedURI: event.blockedURI,
        sourceFile: event.sourceFile,
        lineNumber: event.lineNumber,
        originalPolicy: event.originalPolicy,
        violatedDirective: event.violatedDirective,
      };
      this.notify({ error: new Error('Security Policy Violation'), unhandled: true, debugInfo });
    });
  }

  notify(event: ErrorEvent): void {
    this.bugsnagService.notify(event.error, (bugsnagEvent: Event) => {
      bugsnagEvent.context = event.context ? event.context : bugsnagEvent.context;
      bugsnagEvent.severity = event.severity ? event.severity : bugsnagEvent.severity;
      bugsnagEvent.unhandled = event.unhandled ? event.unhandled : bugsnagEvent.unhandled;
      bugsnagEvent.groupingHash = event.groupingHash ? event.groupingHash : bugsnagEvent.groupingHash;

      if (event.debugInfo) {
        bugsnagEvent.addMetadata('debugInfo', event.debugInfo);
      }
    });
  }
}
