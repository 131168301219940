import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  SimpleChange,
  OnInit,
  AfterViewInit,
  EventEmitter,
  Output,
} from '@angular/core';

import tippy, { Instance, Props } from 'tippy.js';

export const TOOLTIP_SIZES = ['mini', 'regular', 'large', 'regular-thin'] as const;

export const TOOLTIP_PLACEMENTS = [
  'top',
  'top-start',
  'top-end',
  'bottom',
  'bottom-start',
  'bottom-end',
  'right',
  'right-start',
  'right-end',
  'left',
  'left-start',
  'left-end',
] as const;

export type TooltipSize = (typeof TOOLTIP_SIZES)[number];

export type TooltipPlacement = (typeof TOOLTIP_PLACEMENTS)[number];

export type TooltipAppendsTo = 'parent' | Element | ((ref: Element) => Element);

export type TooltipButton = {
  selector: string;
};

export type TooltipDelay = number | [number, number];

export type TooltipOffset = [number, number];

export type EventListenerArrayProps = {
  button: Element;
  listener: () => unknown;
};

/**
 * This is our tooltip directive. A another component or element is required to apply the capra tooltip.
 * The directive and options are attached to the parent component.
 */
@Directive({
  standalone: true,
  selector: '[capraTooltip]',
})
export class TooltipDirective implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  /** Content to be displayed in the tooltip. */
  @Input() tooltipContent = '';

  /** Boolean signifying if the tooltip content is html. */
  @Input() tooltipContentIsHtml = false;

  /** The size of the tooltip */
  @Input() tooltipSize: TooltipSize = 'regular';

  /** The default position of the tooltip */
  @Input() tooltipPlacement: TooltipPlacement = 'top';

  /** Disables the tooltip */
  @Input() tooltipIsDisabled = false;

  /** Open and close delay. Provide a single number for both entry/exit or two numbers for separate entry & exit delays. */
  @Input() tooltipDelay: TooltipDelay = 0; // either a number or an array with two numbers

  /** Force tooltip to be open */
  @Input() tooltipForceShow = false;

  /** Displaces the tippy from its reference element in pixels */
  @Input() tooltipOffset?: TooltipOffset = [0, 10];

  /** Defines the interactivity of the tooltip
   * When true the tooltip is appended to the parent element
   * Update the tooltipAppendTo property if this is not the desired behavior
   */
  @Input() tooltipInteractive = true;

  /** Defines where the tooltip should be appended `('parent' | Element | ((ref: Element) => Element))`
   * @example appendsTo: 'parent' = parent
   * @example appendsTo: Element = elementRef.nativeElement
   * @example appendsTo: ((ref: Element) => Element)) = () = document.body
   */
  @Input() tooltipAppendTo?: TooltipAppendsTo;

  /* Is necessary when we want a clickable button inside
    of the tooltip.
  */
  @Input() tooltipButtonSelectors?: string[];

  /* Passing a click action when a button is present to
    fire an action
  */
  @Output() tooltipButtonClick?: EventEmitter<string> = new EventEmitter<string>();

  tippyInstance: Instance<Props> | undefined;
  targetElement: HTMLElement;
  private eventListenerArray: EventListenerArrayProps[] = [];

  constructor(el: ElementRef) {
    this.targetElement = el.nativeElement;
  }

  ngOnInit() {
    const {
      tooltipContent,
      tooltipContentIsHtml,
      tooltipSize,
      tooltipPlacement,
      tooltipIsDisabled,
      tooltipDelay,
      tooltipForceShow,
      tooltipOffset,
      tooltipInteractive,
      tooltipAppendTo,
    } = this;

    // set the intial values for the tooltip
    const tippyInstance = tippy(this.targetElement, {
      content: tooltipContent,
      allowHTML: tooltipContentIsHtml,
      theme: `capra ${tooltipSize}`,
      placement: tooltipPlacement,
      delay: tooltipDelay,
      interactive: tooltipInteractive,
      offset: tooltipOffset,
      appendTo: tooltipAppendTo,
    });
    // store tippy.js instance for future updates
    this.tippyInstance = tippyInstance;

    // check if the tooltip should initially be disabled
    if (tooltipIsDisabled) {
      this.tippyInstance.disable();
    }
    // check if it should be force open intially
    if (tooltipForceShow) {
      this.forceShow();
    }
  }

  forceShow() {
    // in order to force a show, you can...
    this.tippyInstance?.setProps({
      trigger: 'click', // change the trigger to a "click" event
      hideOnClick: false, // prevent it from listening to clicks on hide
    });
    this.tippyInstance?.show(); // then programmatically show the tooltip
  }

  undoForceShow() {
    // in order to undo a force, reset the trigger and hideOnClick to default values
    this.tippyInstance?.setProps({
      trigger: 'mouseenter focus',
      hideOnClick: true,
    });
    this.tippyInstance?.hide(); // and then programmatically hide the instance
  }

  changeRequiresUpdate(changeRecord: SimpleChange): boolean {
    // only update on...
    return (
      changeRecord && // change records that ...
      !changeRecord.firstChange && // are not the initial values and ...
      changeRecord.currentValue !== changeRecord.previousValue // that have different new values
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const {
      tooltipContent: contentChange,
      tooltipContentIsHtml: isHtmlChange,
      tooltipSize: sizeChange,
      tooltipPlacement: placementChange,
      tooltipIsDisabled: isDisabledChange,
      tooltipDelay: delayChange,
      tooltipForceShow: forceShowChange,
      tooltipOffset: offsetChange,
      tooltipInteractive: interactiveChange,
    } = changes;

    const { changeRequiresUpdate, tippyInstance } = this;

    if (changeRequiresUpdate(contentChange)) {
      tippyInstance?.setContent(contentChange.currentValue);
    }

    if (changeRequiresUpdate(isHtmlChange)) {
      tippyInstance?.setProps({
        allowHTML: isHtmlChange.currentValue,
      });
    }

    if (changeRequiresUpdate(sizeChange)) {
      tippyInstance?.setProps({ theme: `capra ${sizeChange.currentValue}` });
    }

    if (changeRequiresUpdate(placementChange)) {
      tippyInstance?.setProps({ placement: placementChange.currentValue });
    }

    if (changeRequiresUpdate(isDisabledChange)) {
      isDisabledChange.currentValue ? tippyInstance?.disable() : tippyInstance?.enable();
    }

    if (changeRequiresUpdate(delayChange)) {
      tippyInstance?.setProps({ delay: delayChange.currentValue });
    }

    if (changeRequiresUpdate(forceShowChange)) {
      forceShowChange.currentValue ? this.forceShow() : this.undoForceShow();
    }

    if (changeRequiresUpdate(offsetChange)) {
      tippyInstance?.setProps({ offset: offsetChange.currentValue.map(Number) });
    }

    if (changeRequiresUpdate(interactiveChange)) {
      tippyInstance?.setProps({ interactive: interactiveChange.currentValue });
    }
  }

  ngAfterViewInit(): void {
    const buttonsExist = this.tippyInstance && this.tooltipButtonSelectors && this.tooltipButtonSelectors.length > 0;
    if (buttonsExist) {
      this.tippyInstance!.setProps({
        onShow: (instance) => {
          this.tooltipButtonSelectors?.forEach((selector) => {
            const button = instance.popper.querySelector(selector);
            if (button) {
              const dispatchEvent = () => {
                this.eventListenerArray.push({ button: button, listener: dispatchEvent });
                this.tooltipButtonClick?.emit(selector);
              };
              button.addEventListener('click', dispatchEvent);
            }
          });
        },
      });
    }
  }

  ngOnDestroy() {
    this.tippyInstance?.destroy();
    this.eventListenerArray.forEach((listenerObj) =>
      listenerObj.button.removeEventListener('click', listenerObj.listener)
    );
  }
}
