import { Injectable } from '@angular/core';
import { OrgTypes } from '@mode/shared/contract-common';
import { getThoughtspotIntegrated } from './meta-helpers';

@Injectable({ providedIn: 'root' })
export class HelpDocsService {
  private isIntegratedWithTS: boolean | null = null;

  public get links(): HelpLinks {
    if (this.isIntegratedWithTS !== null) {
      return this.isIntegratedWithTS ? thoughtSpotLinks : defaultLinks;
    }

    return getThoughtspotIntegrated(window.document, true) ? thoughtSpotLinks : defaultLinks;
  }

  /*
   *
   * This method was created to deal with our iframe boundary for reports.
   *
   * The iframe's document doesn't have typical meta tags so we have to manually tell
   *  the HelpDocsService if an org is integrated with ThoughtSpot or not.
   *
   * If this method isn't called, then the service will rely on the "thoughtspot-integrated"
   *  meta tag, like normal.
   *
   * @param org - This shoudld be the organization that the user is currently using
   *
   * @returns void
   */
  public setIsThoughtSpotIntegrated(org: OrgTypes.MaybeIntegrated): void {
    this.isIntegratedWithTS = org.isThoughtspotIntegrated;
  }
}

type AllowedUrls = keyof typeof defaultLinks;

type HelpLinks = { [ref in AllowedUrls]: string };

const defaultLinks = {
  'https://mode.com/help': 'https://mode.com/help',
  'https://mode.com/help/articles/ai-assist': 'https://mode.com/help/articles/ai-assist',
  'https://mode.com/help/articles/ai-assist#use-case-examples':
    'https://mode.com/help/articles/ai-assist#use-case-examples',
  'https://mode.com/help/articles/authentication-and-sso': 'https://mode.com/help/articles/authentication-and-sso',
  'https://mode.com/help/articles/authentication-and-sso/#scim':
    'https://mode.com/help/articles/authentication-and-sso/#scim',
  'https://mode.com/help/articles/claimed-domains': 'https://mode.com/help/articles/claimed-domains',
  'https://mode.com/help/articles/connect-to-amazon-redshift':
    'https://mode.com/help/articles/connect-to-amazon-redshift',
  'https://mode.com/help/articles/connect-to-azure-sql': 'https://mode.com/help/articles/connect-to-azure-sql',
  'https://mode.com/help/articles/connect-to-google-bigquery':
    'https://mode.com/help/articles/connect-to-google-bigquery',
  'https://mode.com/help/articles/connecting-mode-to-your-database':
    'https://mode.com/help/articles/connecting-mode-to-your-database',
  'https://mode.com/help/articles/connecting-mode-to-your-database/#bridge':
    'https://mode.com/help/articles/connecting-mode-to-your-database/#bridge',
  'https://mode.com/help/articles/connecting-mode-to-your-database/#do-i-need-to-run-several-bridge-connectors-to-connect-mode-to-several-databases?':
    'https://mode.com/help/articles/connecting-mode-to-your-database/#do-i-need-to-run-several-bridge-connectors-to-connect-mode-to-several-databases?',
  'https://mode.com/help/articles/connecting-mode-to-your-database/#faqs':
    'https://mode.com/help/articles/connecting-mode-to-your-database/#faqs',
  'https://mode.com/help/articles/connecting-to-amazon-athena':
    'https://mode.com/help/articles/connecting-to-amazon-athena',
  'https://mode.com/help/articles/contact-us/': 'https://mode.com/help/articles/contact-us/',
  'https://mode.com/help/articles/dbt-data-freshness': 'https://mode.com/help/articles/dbt-data-freshness',
  'https://mode.com/help/articles/dbt-semantic-layer': 'https://mode.com/help/articles/dbt-semantic-layer',
  'https://mode.com/help/articles/definitions-best-practices':
    'https://mode.com/help/articles/definitions-best-practices',
  'https://mode.com/help/articles/definitions': 'https://mode.com/help/articles/definitions',
  'https://mode.com/help/articles/getting-started-with-mode/#invite-collaborators':
    'https://mode.com/help/articles/getting-started-with-mode/#invite-collaborators',
  'https://mode.com/help/articles/github': 'https://mode.com/help/articles/github',
  'https://mode.com/help/articles/google-sheets-integration':
    'https://mode.com/help/articles/google-sheets-integration',
  'https://mode.com/help/articles/internal-embeds': 'https://mode.com/help/articles/internal-embeds',
  'https://mode.com/help/articles/internal-embeds/#filter-panel-toggle':
    'https://mode.com/help/articles/internal-embeds/#filter-panel-toggle',
  'https://mode.com/help/articles/member-api-tokens':
    'https://mode.com/help/articles/api-reference#generating-api-tokens',
  'https://mode.com/help/articles/notebook': 'https://mode.com/help/articles/notebook',
  'https://mode.com/help/articles/notebook/#accessing-query-results':
    'https://mode.com/help/articles/notebook/#accessing-query-results',
  'https://mode.com/help/articles/notebook/#faqs': 'https://mode.com/help/articles/notebook/#faqs',
  'https://mode.com/help/articles/notebook/#install-additional-libraries':
    'https://mode.com/help/articles/notebook/#install-additional-libraries',
  'https://mode.com/help/articles/notebook/#python': 'https://mode.com/help/articles/notebook/#python',
  'https://mode.com/help/articles/notebook#python-notebooks-secrets-store':
    'https://mode.com/help/articles/notebook#python-notebooks-secrets-store',
  'https://mode.com/help/articles/parameters': 'https://mode.com/help/articles/parameters',
  'https://mode.com/help/articles/permissions/#set-up-connection-permissions':
    'https://mode.com/help/articles/permissions/#set-up-connection-permissions',
  'https://mode.com/help/articles/querying-data/?utm_source=result_limit_cta#sorry,-this-data-is-larger-than-your-limit':
    'https://mode.com/help/articles/querying-data/?utm_source=result_limit_cta#sorry,-this-data-is-larger-than-your-limit',
  'https://mode.com/help/articles/querying-data/#sql-keyboard-shortcuts':
    'https://mode.com/help/articles/querying-data/#sql-keyboard-shortcuts',
  'https://mode.com/help/articles/quick-reference-guide': 'https://mode.com/help/articles/quick-reference-guide',
  'https://mode.com/help/articles/report-scheduling-and-sharing':
    'https://mode.com/help/articles/report-scheduling-and-sharing',
  'https://mode.com/help/articles/report-scheduling-and-sharing/#email':
    'https://mode.com/help/articles/report-scheduling-and-sharing/#email',
  'https://mode.com/help/articles/report-scheduling-and-sharing/#external-sharing':
    'https://mode.com/help/articles/report-scheduling-and-sharing/#external-sharing',
  'https://mode.com/help/articles/report-scheduling-and-sharing/#slack':
    'https://mode.com/help/articles/report-scheduling-and-sharing/#slack',
  'https://mode.com/help/articles/slack/#setting-up': 'https://mode.com/help/articles/slack/#setting-up',
  'https://mode.com/help/articles/spaces/#personal-collection':
    'https://mode.com/help/articles/spaces/#personal-collection',
  'https://mode.com/help/articles/using-query-headers': 'https://mode.com/help/articles/using-query-headers',
  'https://mode.com/help/articles/visual-explorer': 'https://mode.com/help/articles/visual-explorer',
  'https://mode.com/help/articles/visual-explorer#table-calculation-settings':
    'https://mode.com/help/articles/visual-explorer#table-calculation-settings',
  'https://mode.com/help/articles/white-label-embeds': 'https://mode.com/help/articles/white-label-embeds',
  'https://mode.com/help/articles/workspace-api-tokens':
    'https://mode.com/help/articles/api-reference#generating-api-tokens',
  'https://mode.com/help/connect-your-database': 'https://mode.com/help/connect-your-database',
  'https://mode.com/help/definitions': 'https://mode.com/help/definitions',
  'https://mode.com/help/get-started': 'https://mode.com/help/get-started',
};

const thoughtSpotLinks = {
  'https://mode.com/help': 'https://docs.thoughtspot.com/cloud/latest/',
  'https://mode.com/help/articles/ai-assist': 'https://docs.thoughtspot.com/cloud/latest/analyst-studio-ai-assist',
  'https://mode.com/help/articles/ai-assist#use-case-examples':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-ai-assist#use-case-examples',
  'https://mode.com/help/articles/authentication-and-sso': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/authentication-and-sso/#scim': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/claimed-domains': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/connect-to-amazon-redshift':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-supported-databases#amazon-redshift',
  'https://mode.com/help/articles/connect-to-azure-sql':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-supported-databases#azure-sql-database',
  'https://mode.com/help/articles/connect-to-google-bigquery':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-supported-databases#google-bigquery',
  'https://mode.com/help/articles/connecting-mode-to-your-database':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-connecting-analyst-studio-to-your-database',
  'https://mode.com/help/articles/connecting-mode-to-your-database/#bridge':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-connecting-analyst-studio-to-your-database#bridge',
  'https://mode.com/help/articles/connecting-mode-to-your-database/#do-i-need-to-run-several-bridge-connectors-to-connect-mode-to-several-databases?':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-connecting-analyst-studio-to-your-database',
  'https://mode.com/help/articles/connecting-mode-to-your-database/#faqs':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-connecting-analyst-studio-to-your-database',
  'https://mode.com/help/articles/connecting-to-amazon-athena':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-supported-databases#amazon-athena',
  'https://mode.com/help/articles/contact-us/': 'https://docs.thoughtspot.com/cloud/latest/support-contact',
  'https://mode.com/help/articles/dbt-data-freshness':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-dbt-data-freshness',
  'https://mode.com/help/articles/dbt-semantic-layer':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-dbt-semantic-layer',
  'https://mode.com/help/articles/definitions-best-practices':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-definitions',
  'https://mode.com/help/articles/definitions': 'https://docs.thoughtspot.com/cloud/latest/analyst-studio-definitions',
  'https://mode.com/help/articles/getting-started-with-mode/#invite-collaborators':
    'Removed from integrated workspaces',
  'https://mode.com/help/articles/github': 'https://docs.thoughtspot.com/cloud/latest/analyst-studio-github',
  'https://mode.com/help/articles/google-sheets-integration':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-google-sheets-integration',
  'https://mode.com/help/articles/internal-embeds': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/internal-embeds/#filter-panel-toggle': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/member-api-tokens':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-api-tokens#_member_tokens',
  'https://mode.com/help/articles/notebook': 'https://docs.thoughtspot.com/cloud/latest/analyst-studio-notebook',
  'https://mode.com/help/articles/notebook/#accessing-query-results':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-notebook#accessing-query-results',
  'https://mode.com/help/articles/notebook/#faqs':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-notebook#faqs',
  'https://mode.com/help/articles/notebook/#install-additional-libraries':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-notebook#install-additional-libraries',
  'https://mode.com/help/articles/notebook/#python':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-notebook#python',
  'https://mode.com/help/articles/notebook#python-notebooks-secrets-store':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-notebook#_python_notebooks_secrets_store',
  'https://mode.com/help/articles/parameters': 'https://docs.thoughtspot.com/cloud/latest/analyst-studio-parameters',
  'https://mode.com/help/articles/permissions/#set-up-connection-permissions':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-permissions#set-up-collection-permissions',
  'https://mode.com/help/articles/querying-data/?utm_source=result_limit_cta#sorry,-this-data-is-larger-than-your-limit':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-querying-data',
  'https://mode.com/help/articles/querying-data/#sql-keyboard-shortcuts':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-querying-data#sql-keyboard-shortcuts',
  'https://mode.com/help/articles/quick-reference-guide': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/report-scheduling-and-sharing':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-report-scheduling-and-sharing',
  'https://mode.com/help/articles/report-scheduling-and-sharing/#email': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/report-scheduling-and-sharing/#external-sharing':
    'Removed from integrated workspaces',
  'https://mode.com/help/articles/report-scheduling-and-sharing/#slack': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/slack/#setting-up': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/spaces/#personal-collection':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-spaces',
  'https://mode.com/help/articles/using-query-headers':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-managing-database-connections#query-headers',
  'https://mode.com/help/articles/visual-explorer':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-visual-explorer',
  'https://mode.com/help/articles/visual-explorer#table-calculation-settings':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-visual-explorer#table-calculation-settings',
  'https://mode.com/help/articles/white-label-embeds': 'Removed from integrated workspaces',
  'https://mode.com/help/articles/workspace-api-tokens':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-workspace-api-tokens',
  'https://mode.com/help/connect-your-database':
    'https://docs.thoughtspot.com/cloud/latest/analyst-studio-connect-your-database',
  'https://mode.com/help/definitions': 'https://docs.thoughtspot.com/cloud/latest/analyst-studio-definitions',
  'https://mode.com/help/get-started': 'https://docs.thoughtspot.com/cloud/latest/analyst-studio-getting-started',
};
