import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sendFeedback } from './ai-assist.actions';

type PermittedActions = ReturnType<(typeof SubmitFeedbackFacade.Actions)[keyof typeof SubmitFeedbackFacade.Actions]>;
@Injectable()
export class SubmitFeedbackFacade {
  static Actions = { sendFeedback };

  constructor(private store: Store) {}

  public dispatch(action: PermittedActions) {
    this.store.dispatch(action);
  }
}
